<template>
    <div id="interactive" class="viewport scanner">
        <video />
        <canvas class="drawingBuffer" />
    </div>
</template>

<script>
import Quagga from '@ericblade/quagga2';

export default {
    props: {
        onDetected: {
            type: Function,
            default(result) {
                console.log('detected: ', result);
            },
        },
        onProcessed: {
            type: Function,
            default(result) {
                let drawingCtx = Quagga.canvas.ctx.overlay;

                let drawingCanvas = Quagga.canvas.dom.overlay;

                if (result) {
                    if (result.boxes) {
                        drawingCtx.clearRect(
                            0,
                            0,
                            parseInt(drawingCanvas.getAttribute('width')),
                            parseInt(drawingCanvas.getAttribute('height'))
                        );
                        result.boxes
                            .filter(function (box) {
                                return box !== result.box;
                            })
                            .forEach(function (box) {
                                Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                                    color: 'red',
                                    lineWidth: 3,
                                });
                            });
                    }
                    if (result.box) {
                        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
                            color: '#00F',
                            lineWidth: 3,
                        });
                    }

                    if (result.codeResult && result.codeResult.code) {
                        Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, {
                            color: 'green',
                            lineWidth: 4,
                        });
                    }
                }
            },
        },
        readerTypes: {
            type: Array,
            default: () => ['ean_reader', 'ean_8_reader'],
        },
        readerSize: {
            type: Object,
            default: () => ({
                width: 640,
                height: 480,
            }),
            validator: (o) => typeof o.width === 'number' && typeof o.height === 'number',
        },
        aspectRatio: {
            type: Object,
            default: () => ({
                min: 1,
                max: 2,
            }),
            validator: (o) => typeof o.min === 'number' && typeof o.max === 'number',
        },
    },
    data: function () {
        return {
            quaggaState: {
                inputStream: {
                    type: 'LiveStream',
                    constraints: {
                        width: { min: this.readerSize.width },
                        height: { min: this.readerSize.height },
                        facingMode: 'environment',
                        aspectRatio: { min: 1, max: 2 },
                    },
                },
                locator: {
                    patchSize: 'medium',
                    halfSample: true,
                },
                numOfWorkers: 4,
                frequency: 70,
                decoder: {
                    readers: this.readerTypes,
                },
                locate: true,
            },
        };
    },
    watch: {
        onDetected: function (oldValue, newValue) {
            if (oldValue) Quagga.offDetected(oldValue);
            if (newValue) Quagga.onDetected(newValue);
        },
        onProcessed: function (oldValue, newValue) {
            if (oldValue) Quagga.offProcessed(oldValue);
            if (newValue) Quagga.onProcessed(newValue);
        },
    },
    mounted: function () {
        Quagga.init(this.quaggaState, function (err) {
            if (err) {
                return console.error(err);
            }
            Quagga.start();
        });
        Quagga.onDetected(this.onDetected);
        Quagga.onProcessed(this.onProcessed);
    },
    unmounted: function () {
        if (this.onDetected) Quagga.offDetected(this.onDetected);
        if (this.onProcessed) Quagga.offProcessed(this.offProcessed);
        Quagga.stop();
    },
};
</script>

<style scoped>
.viewport {
    position: relative;
    top: 56px;
}

.viewport canvas,
.viewport video {
    position: absolute;
    width: 100vw;
    left: 0;
    top: 0;
}
</style>

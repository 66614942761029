<template>
    <div class="tooltip">
        <img src="../assets/img/questionMark.svg" alt="balão de ajuda" />
        <span
            :class="{
                'tooltip-top': isTop,
                'tooltip-left': isLeft,
                'tooltip-right': isRight,
                'tooltip-bottom': isBottom,
            }"
            >{{ msg }}</span
        >
    </div>
</template>

<script>
export default {
    props: {
        isTop: {
            type: Boolean,
        },

        isLeft: {
            type: Boolean,
        },

        isRight: {
            type: Boolean,
        },

        isBottom: {
            type: Boolean,
        },

        msg: {
            type: String,
        },
    },
};
</script>

<style scoped>
.tooltip {
    position: relative;
    display: flex;
    margin-left: 10px;
    cursor: pointer;
}

.tooltip img {
    width: 18px;
    height: 18px;
}

.tooltip span {
    visibility: hidden;
    position: absolute;
    background-color: #565656;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    font-size: 14px;
    margin-left: -60px;
    z-index: 2;
}

.tooltip:hover span {
    visibility: visible;
}

.tooltip-left {
    width: 160px;
    left: -5.5rem;
    bottom: -2rem;
}

.tooltip-top {
    width: 220px;
    left: -1rem;
    bottom: 1rem;
}

.tooltip-right {
    width: 160px;
    left: -5.5rem;
    bottom: -2rem;
}

.tooltip-bottom {
    width: 200px;
    left: 0.5rem;
    bottom: -6.2rem;
}
</style>

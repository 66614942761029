<template>
    <div>
        <div v-show="!isScanning" class="container-form">
            <form action="">
                <div v-show="showProduct" class="container container-produto">
                    <div class="label label-produto">
                        <span class="produto"> Produto </span>
                    </div>
                    <div class="produto-img">
                        <img id="imagemProduto" src="" @error="replaceByDefault" alt="produto 1" />
                    </div>
                </div>
                <div class="container-codigo">
                    <div class="label label-codigo">
                        <span class="codigo"> Código de barras <span class="obrigatorio">*</span> </span>
                        <tooltip :isBottom="true" :msg="msgCodigo"></tooltip>
                    </div>
                    <div class="input input-codigo">
                        <input
                            type="number"
                            autocorrect="off"
                            min="13"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            max="9999999999999"
                            maxlength="13"
                            placeholder="0000000000000"
                            v-model="codigo"
                            id="inputCodigo"
                            @input="validaCodigo"
                            required
                        />
                        <button class="btn-scan" type="button" @click="isScanning = true">
                            <img src="../../assets/img/camera-black.png" alt="abrir camera para scan" />
                        </button>
                        <button id="btn-audio" class="btn-audio" type="button" @click="ativaAudio">
                            <img src="../../assets/img/mic.svg" alt="Abrir microfone" />
                        </button>
                    </div>
                    <div v-if="codigoIsInvalid" class="mensagemErro">Informação Obrigatória</div>
                </div>
                <div class="container container-descricao" v-show="descricao != '' && codigo != ''">
                    <div class="label label-descricao">
                        <span class="descricao"> Descrição </span>
                    </div>
                    <div class="input input-descricao">
                        <input v-model="descricao" type="text" placeholder="" disabled />
                    </div>
                </div>
                <div class="container" v-show="altoGiro != '' && codigo != ''">
                    <div class="label">
                        <span class="descricao"> O produto é alto giro? </span>
                    </div>
                    <div class="input input-descricao">
                        <input v-model="altoGiro" type="text" placeholder="" disabled />
                    </div>
                </div>
                <div class="containerQV">
                    <div class="container container-validade">
                        <div class="label label-validade">
                            <span class="validade"> Data de validade <span class="obrigatorio">*</span> </span>
                            <tooltip :isTop="true" :msg="msgValidade"></tooltip>
                        </div>
                        <div class="input input-validade">
                            <input
                                class="inputQV"
                                type="date"
                                autocorrect="off"
                                autocomplete="off"
                                :min="dataAtual(regraRetirada)"
                                max="2025-12-31"
                                placeholder="dd/mm/aaaa"
                                v-model="validade"
                                ref="dataValidade"
                                @input="validaDate"
                                required
                            />
                        </div>
                        <div v-if="dataIsInvalid.valida" class="mensagemErro">{{ dataIsInvalid.mensagem }}</div>
                    </div>
                    <div class="container container-quantidade">
                        <div class="label label-quantidade">
                            <span class="quantidade"> Quantidade <span class="obrigatorio">*</span> </span>
                            <tooltip class="tooltip-qnt" :isLeft="true" :msg="msgQnt"></tooltip>
                        </div>
                        <div class="input input-quantidade">
                            <input
                                class="inputQV"
                                type="number"
                                min="0"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                max="999999"
                                maxlength="6"
                                placeholder="000000"
                                v-model="quantidade"
                                id="inputQuantidade"
                                @input="validaQuantidade"
                                required
                            />

                            <div class="controle">
                                <button class="btn-minus" type="button" @click="decrementQuantidade">
                                    <img src="../../assets/img/minus.svg" alt="subtrair" />
                                </button>
                                <button class="btn-plus" type="button" @click="incrementQuantidade">
                                    <img src="../../assets/img/plus.svg" alt="adicionar" />
                                </button>
                            </div>
                        </div>
                        <div v-if="quantidadeIsInvalid" class="mensagemErro">Informação Obrigatória</div>
                    </div>
                </div>

                <div class="container container-solicitado">
                    <div class="label label-solicitado">
                        <span class="preco-solicitado">Preço Solicitado <span class="obrigatorio">*</span></span>
                    </div>
                    <div class="input input-solicitado">
                        <input
                            type="number"
                            min="1"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            max="999999"
                            maxlength="6"
                            placeholder="000000"
                            v-model="precoSolicitado"
                            id="inputPrecoSolicitado"
                            @input="validaPrecoSolicitado"
                            required
                        />
                    </div>
                    <div v-if="preco2IsInvalid.valida" class="mensagemErro">{{ preco2IsInvalid.mensagem }}</div>
                </div>

                <div class="container container-anexos">
                    <div class="label label-anexos">
                        <span class="anexos">Comprovações <span class="obrigatorio">*</span></span>
                        <tooltip :isTop="true" :msg="msgAnexos"></tooltip>
                    </div>
                    <div class="input input-anexos">
                        <input
                            type="file"
                            id="file1"
                            ref="imagensAdicionais"
                            name="file"
                            accept="image/png, image/jpeg"
                            multiple
                            @change="checkFiles()"
                        />
                    </div>
                    <div v-if="fileIsInvalid" class="mensagemErro">É necessário incluir 2 ou mais imagens</div>
                </div>
            </form>
        </div>

        <!-- <stream-barcode-reader v-if="isScanning" @decode="onDecode"></stream-barcode-reader> -->
        <barcode-scanner v-if="isScanning" :onDetected="logIt"></barcode-scanner>

        <footer-save :isSending="isSending" @editaInclusao="editaInclusao" @envia-form="enviaForm"></footer-save>

        <transition name="fade">
            <div v-if="showConfirm" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        Descartar alterações?
                        <div class="close-cancel" @click="showConfirm = false">
                            <img src="../../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <h3>{{ msgConfirm }}</h3>
                    <div class="cancel-btn">
                        <button
                            @click="
                                closeModal;
                                showConfirm = false;
                            "
                            type="button"
                        >
                            Não
                        </button>
                        <button
                            @click="
                                descarteProduto();
                                limpaCampos();
                            "
                            type="button"
                        >
                            Descartar
                        </button>
                    </div>
                </div>
            </div>
        </transition>

        <tela-sync v-if="sincronizing" @closePopUp="closePopUp" :msgSync="msgSync"></tela-sync>

        <msg-sucess v-if="showSucess" :isRed="isRed" :mensagemSucess="msgS"></msg-sucess>
        <msg-error v-if="showAlert" @closeError="closeError" :mensagemError="msgE"></msg-error>
    </div>
</template>

<script>
import FooterSave from '../../components/FooterSave';
import TelaSync from '../../components/TelaSync.vue';
import Tooltip from '../../components/Tooltip';
import HTTP from '../../http/http.js';
import MsgSucess from '../../components/MsgSucess';
import MsgError from '../../components/MsgError';
import img from '../../assets/img/broken-image.svg';
import BarcodeScanner from '../../components/barcode/BarcodeScanner.vue';
// import StreamBarcodeReader from '../components/barcode/StreamBarcodeReader.vue';
import moment from 'moment';

export default {
    data() {
        return {
            codigo: '',
            validade: '',
            quantidade: '',
            descricao: '',
            altoGiro: '',
            showProduct: false,
            showSucess: false,
            showAlert: false,
            codigoIsInvalid: false,
            fileIsInvalid: false,
            dataIsInvalid: {
                mensagem: '',
                valida: false,
            },
            quantidadeIsInvalid: false,
            precoSolicitadoIsInvalid: false,
            isScanning: false,
            isSending: false,
            categoria_tarefa: '',
            categoria_2: '',
            suggestions: [],
            showValidade: false,
            showQuantidade: false,
            showConfirm: false,
            msgCodigo:
                'Número de até 13 dígitos representado por barras verticais usado para a identificação do produto, use a câmera para escanear o código.',
            msgValidade:
                'Validades existentes do produto a ser incluído, podem existir mais de 1 data de validade para o mesmo produto.',
            msgQnt: 'Quantidade existente do produto a ser incluído na data de validade informada.',
            msgS: 'Produto incluido com sucesso',
            msgAnexos: 'Adicione 2 imagens, comprovando quantidade e validade.',
            msgE: '',
            recognition: null,
            msgConfirm: '',
            precoSolicitado: '',
            anexo: [],
            anexosAdicionais: [],
            imagensAdicionais: null,
            preco1IsInvalid: {
                mensagem: '',
                valida: false,
            },
            preco2IsInvalid: {
                mensagem: '',
                valida: false,
            },
            sincronizing: false,
            msgSync: 'Salvando... Por favor, aguarde.',
            regraRetirada: 0,
            isRed: false,
            hasProduct: false,
        };
    },

    components: {
        FooterSave,
        Tooltip,
        MsgSucess,
        MsgError,
        TelaSync,
        BarcodeScanner,
        // StreamBarcodeReader,
    },

    computed: {
        getProduto() {
            return this.$store.getters.getProduto;
        },
        getTodosProdutos() {
            return this.$store.getters.getTodosProdutos;
        },
        getCodigoInclusao() {
            return this.$store.getters.getCodigoInclusao;
        },
        getPrecos() {
            return this.$store.getters.getPrecos;
        },
        getPedidos() {
            return this.$store.getters.getPedidos;
        },
    },

    mounted() {
        if (this.getCodigoInclusao) {
            this.codigo = this.getCodigoInclusao;

            this.getDataProduct();
        }

        try {
            let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            this.recognition = new SpeechRecognition();
            this.recognition.lang = 'pt-BR';

            this.recognition.onstart = function () {
                let elemento = document.getElementById('btn-audio');
                elemento.id = 'btnVoiceActivated';
            };

            this.recognition.onspeechend = function () {
                let elemento = document.getElementById('btnVoiceActivated');
                elemento.id = 'btn-audio';
            };

            this.recognition.onerror = function (event) {
                if (event.error == 'no-speech') {
                    // console.log('No speech was detected. Try again.');
                }
            };

            this.recognition.onresult = (event) => {
                let current = event.resultIndex;

                let transcript = event.results[current][0].transcript;

                let codigoFiltrado = transcript.replace(/\D/g, '');

                this.codigo = codigoFiltrado;

                this.getDataProduct();
            };
        } catch (e) {
            console.error(e);
        }
    },

    methods: {
        ativaAudio() {
            this.recognition.start();
        },

        onDecode(result) {
            this.descricao = '';
            this.categoria_2 = '';
            this.categoria_tarefa = '';

            this.suggestions = [];

            this.showProduct = false;

            this.codigo = result;

            this.getDataProduct();

            this.isScanning = false;
        },

        logIt(data) {
            let countDecodedCodes = 0;
            let err = 0;
            let decodesArray = data.codeResult.decodedCodes;

            decodesArray.forEach((element) => {
                if (element.error != undefined) {
                    countDecodedCodes++;
                    err += parseFloat(element.error);
                }
            });

            if (err / countDecodedCodes < 0.2) {
                let code = data.codeResult.code;
                this.descricao = '';
                this.categoria_2 = '';
                this.categoria_tarefa = '';

                this.suggestions = [];

                this.showProduct = false;

                this.codigo = code;

                this.getDataProduct();

                this.isScanning = false;
            }
        },

        dataFormatada(diaDB) {
            if (diaDB != '') {
                let dateSplit = diaDB.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        getUniqueListBy(arr, key) {
            return [...new Map(arr.map((item) => [item[key], item])).values()];
        },

        handleFileUpload(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            this.createImage(files[0]);
        },

        checkFiles() {
            let fup = document.getElementById('file1');
            let fileName = fup.value;
            let ext = fileName.substring(fileName.lastIndexOf('.') + 1);
            // this.validaFile();

            if (ext == 'jpeg' || ext == 'png') {
                // this.imagensAdicionais = this.$refs.file.files;
                return true;
            } else {
                return false;
            }
        },

        validaFile() {
            let file = document.getElementById('file1');
            let count = file.files.length;
            if (count < 2) {
                this.fileIsInvalid = true;
            } else {
                this.fileIsInvalid = false;
            }
        },

        getDataProduct() {
            this.suggestions = [];

            let todosProdutos = this.getTodosProdutos;

            let produtoFiltrado = todosProdutos.filter((element) => element.codigo_de_barras == this.codigo);

            if (produtoFiltrado.length == 0) {
                this.descricao = '';
                this.showProduct = false;
                this.hasProduct = false;
                return;
            }

            if (produtoFiltrado[0] != '') {
                this.categoria_2 = produtoFiltrado[0].categoria_2;
                this.descricao = produtoFiltrado[0].descricao;
                this.regraRetirada = produtoFiltrado[0].regra_retirada;
                this.hasProduct = true;
                if (produtoFiltrado[0].status != null) {
                    this.altoGiro = 'Sim';
                } else {
                    this.altoGiro = 'Não';
                }
                // this.showProduct = true;

                this.suggestions.push(produtoFiltrado[0].categoria_2);

                if (this.codigo) {
                    let img = document.getElementById('imagemProduto');
                    img.setAttribute(
                        'src',
                        'https://147266017877-app-images.s3.sa-east-1.amazonaws.com/' + this.codigo + '.webp'
                    );
                }
            } else {
                this.descricao = '';
            }
        },

        enviaForm() {
            if (!this.verificaValidade()) return;
            if (!this.validaFormulario()) return;

            let existeValidade = false;

            let pedidosPendentes = this.getPedidos;

            pedidosPendentes.forEach((element) => {
                if (element.codigo_de_barras == this.codigo && element.data_validade == this.validade) {
                    this.msgE = `Já foi feito um pedido com a validade ${this.convertDate(
                        this.validade
                    )}. Caso queira alterar alguma informação, cancele o pedido e faça novamente.`;
                    this.showAlert = true;
                    existeValidade = true;
                }
            });

            if (existeValidade) {
                return;
            }

            this.sincronizing = true;

            this.isSending = true;

            let loja = sessionStorage.getItem('loja');
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = yyyy + '-' + mm + '-' + dd;

            let usuario = sessionStorage.getItem('usuario');

            let data = {
                codigo_de_barras: this.codigo,
                validade: this.validade,
                data_solicitacao: today,
                quantidade: this.quantidade,
                preco_solicitado: this.precoSolicitado,
                categoria_2: this.categoria_2,
                imagens: this.imagensAdicionais,
                nome: usuario,
            };

            let formData = new FormData();
            formData.append('codigo_de_barras', this.codigo);
            formData.append('validade', this.validade);
            formData.append('quantidade', this.quantidade);
            formData.append('categoria_2', this.quantidade);
            formData.append('preco_solicitado', this.precoSolicitado);
            formData.append('nome', usuario);

            for (var i = 0; i < this.$refs.imagensAdicionais.files.length; i++) {
                let file = this.$refs.imagensAdicionais.files[i];
                formData.append('files[' + i + ']', file);
            }

            let dataVuex = {
                codigo_de_barras: this.codigo,
                data_solicitacao: today,
                data_validade: this.validade,
                descricao: this.descricao,
                loja: loja,
                preco_solicitado: this.precoSolicitado,
                quantidade: this.quantidade,
                categoria_2: this.categoria_2,
                status: 'Pendente',
                tipo_status: this.altoGiro,
            };

            this.$store.dispatch('setNovoPreco', dataVuex);

            if (navigator.onLine) {
                let loja = sessionStorage.getItem('loja');

                HTTP.post(`${loja}/admin/precos`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(() => {
                        this.closePopUp();
                        this.isSending = false;
                        const d = new Date();
                        let day = d.getDay();
                        let horas = d.getHours();
                        if ((day == 1 && horas >= 11) || (day == 4 && horas >= 11)) {
                            this.msgS = 'Pedido enviado. Será analisado na próxima validação.';
                            this.isRed = true;
                            this.showSucess = true;
                            setTimeout(() => {
                                this.showSucess = false;
                                this.$router.go(-1);
                            }, 4000);
                        } else {
                            this.msgS = 'Pedido enviado com sucesso!';
                            this.showSucess = true;
                            setTimeout(() => {
                                this.showSucess = false;
                                this.$router.go(-1);
                            }, 3000);
                        }
                    })
                    .catch(() => {
                        this.closePopUp();
                        /* this.isInvalid = true; */
                        console.error('erro');
                    });
            } else {
                let precosSalvos = JSON.parse(localStorage.getItem('precos'));

                let dataToLS = null;

                if (precosSalvos) {
                    precosSalvos.push(data);

                    dataToLS = JSON.stringify(precosSalvos);
                } else {
                    dataToLS = JSON.stringify([data]);
                }

                this.closePopUp();
                this.msgS = 'Seu pedido será enviado ao sincronizar!';
                this.showSucess = true;

                localStorage.setItem('precos', dataToLS);

                this.$store.dispatch('plusNumeroSync');

                this.isSending = false;

                setTimeout(() => {
                    this.showSucess = false;
                    this.$router.go(-1);
                }, 3000);
            }
        },

        editaInclusao() {
            this.showConfirm = true;
            this.msgConfirm = 'Deseja realmente cancelar o pedido?';
        },

        descarteProduto() {
            this.$router.go(-1);
        },

        convertDate(date) {
            if (date != '') {
                let dateSplit = date.split('-');
                let newDate = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
                return newDate;
            } else {
                return '';
            }
        },

        decrementQuantidade() {
            this.quantidade--;
            if (this.quantidade < 0) {
                this.quantidade = 0;
            }
        },

        adicionaAnexo() {
            this.anexosAdicionais.push({
                quantidade: 0,
                validade: '',
            });
        },

        removeAnexo(currentIndex) {
            this.anexosAdicionais.splice(
                this.anexosAdicionais.findIndex((a, index) => index == currentIndex),
                1
            );
        },

        incrementQuantidade() {
            this.quantidade++;
            if (this.quantidade > 99999) {
                this.quantidade = 99999;
            }
        },

        validaQuantidade() {
            var input = document.querySelector('#inputQuantidade');
            this.quantidadeIsInvalid = this.quantidade < 0 ? true : false;

            if (this.quantidade.toString().length > input.maxLength) {
                this.quantidade = parseFloat(this.quantidade.toString().slice(0, input.maxLength));
            }

            input.addEventListener('keypress', function (e) {
                if (!checkChar(e)) {
                    e.preventDefault();
                }
            });
            function checkChar(e) {
                var char = String.fromCharCode(e.keyCode);

                var pattern = '[0-9]';
                if (char.match(pattern)) {
                    return true;
                }
            }
        },

        validaPrecoSolicitado() {
            var input = document.querySelector('#inputPrecoSolicitado');
            this.precoSolicitadoIsInvalid = this.precoSolicitado <= 0 ? true : false;

            if (this.precoSolicitadoIsInvalid) {
                this.preco2IsInvalid = {
                    mensagem: 'Informação Obrigatória',
                    valida: true,
                };
            } else {
                this.preco2IsInvalid = {
                    mensagem: '',
                    valida: false,
                };
            }

            if (this.precoSolicitado.toString().length > input.maxLength) {
                this.precoSolicitado = parseFloat(this.precoSolicitado.toString().slice(0, input.maxLength));
            }

            input.addEventListener('keypress', function (e) {
                if (!checkChar(e)) {
                    e.preventDefault();
                }
            });
            function checkChar(e) {
                var char = String.fromCharCode(e.keyCode);

                var pattern = '[0-9,.]';
                if (char.match(pattern)) {
                    return true;
                }
            }
        },

        validaCodigo() {
            var input = document.querySelector('#inputCodigo');
            this.codigoIsInvalid = this.codigo == '' ? true : false;
            this.getDataProduct();

            if (this.codigo.toString().length > input.maxLength) {
                this.codigo = parseFloat(this.codigo.toString().slice(0, input.maxLength));
            }

            input.addEventListener('keypress', function (e) {
                if (!checkChar(e)) {
                    e.preventDefault();
                }
            });
            function checkChar(e) {
                var char = String.fromCharCode(e.keyCode);

                var pattern = '[0-9]';
                if (char.match(pattern)) {
                    return true;
                }
            }
        },

        validaDate() {
            let data = new Date();
            let dataHoje = '';
            let dia = String(data.getDate() + 2).padStart(2, '0');
            let mes = String(data.getMonth() + 1).padStart(2, '0');
            let ano = data.getFullYear();
            dataHoje = ano + '-' + mes + '-' + dia;

            if (this.validade < dataHoje) {
                this.dataIsInvalid = {
                    mensagem: 'Data inválida!',
                    valida: true,
                };
            } else {
                this.dataIsInvalid = {
                    mensagem: '',
                    valida: false,
                };
            }
        },

        validaFormulario() {
            this.codigoIsInvalid = this.codigo == '' ? true : false;

            if (this.dataIsInvalid.valida == true) {
                this.dataIsInvalid = {
                    mensagem: 'Data inválida!',
                    valida: true,
                };
            } else {
                if (this.validade == '') {
                    this.dataIsInvalid = {
                        mensagem: 'Informação Obrigatória',
                        valida: true,
                    };
                } else {
                    this.dataIsInvalid = {
                        mensagem: '',
                        valida: false,
                    };
                }
            }

            this.quantidadeIsInvalid = this.quantidade < 0 || this.quantidade === '' ? true : false;
            this.precoSolicitadoIsInvalid = this.precoSolicitado <= 0 || this.precoSolicitado === '' ? true : false;

            // this.validaFile();

            if (
                this.codigoIsInvalid == true ||
                this.dataIsInvalid.valida == true ||
                this.quantidadeIsInvalid == true ||
                this.precoSolicitadoIsInvalid == true ||
                this.fileIsInvalid == true
            ) {
                return false;
            }

            return true;
        },

        verificaValidade() {
            let dateInput = new Date(this.validade + ' 00:00');

            let maxDate = new Date();
            maxDate.setFullYear(maxDate.getFullYear() + 4);

            if (dateInput > maxDate) {
                this.dataIsInvalid = {
                    mensagem: 'Data de validade precisa ser menor que 4 anos',
                    valida: true,
                };
                return false;
            }

            return true;
        },

        replaceByDefault(e) {
            e.target.src = img;
        },

        closePopUp() {
            this.sincronizing = false;
        },

        dataAtual(regraRetirada) {
            let data = new Date();
            let day = data.getDay();
            let momentData = moment();
            let dataHoje = '';
            let regraDia = 0;
            if (this.hasProduct == false) {
                regraRetirada = 2;
            }
            if (day == 5) {
                regraDia = 3;
            } else if (day == 6 || day == 2) {
                regraDia = 2;
            } else if (day == 3 || day == 0) {
                regraDia = 1;
            } else if (day == 1 || day == 4) {
                regraDia = 0;
            }
            let dia = momentData.add(2, 'd').add(regraRetirada, 'd').add(regraDia, 'd');
            dataHoje = dia.format('YYYY-MM-DD');
            return dataHoje;
        },

        // dataMax() {
        //     let date = moment();
        //     let dataMaxima = date.add(15, 'd').format('YYYY-MM-DD');
        //     return dataMaxima;
        // },

        limpaCampos() {
            this.codigo = '';
        },

        closeError() {
            this.showAlert = false;
        },
    },
};
</script>

<style scoped>
.container-form {
    padding: 15px;
    margin-top: 56px;
    margin-bottom: 66px;
    overflow-x: hidden;
}

.adc-validade,
.menos-validade {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #cdcdcd;
    padding: 5px;
    border-radius: 20px;
    background-color: #f4f4f4;
    cursor: pointer;
    transition: all 0.2s;
}

.adc-validade:hover,
.menos-validade:hover {
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #fff;
}

.adc-validade:hover h3,
.menos-validade:hover h3 {
    color: var(--vermelho);
}

.nova-validade {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: space-between;
}

.nova-validade h3 {
    color: var(--cinza-escuro);
    font-size: 14px;
}

.nova-validade h3 small {
    font-size: 11px;
}

.obrigatorio {
    color: var(--vermelho);
}

form div div span {
    color: var(--cinza-escuro);
}

.produto-img {
    display: flex;
    margin-top: 9px;
    margin-bottom: 15px;
    border: 1px solid #bdc1c6;
    padding: 10px;
    justify-content: center;
    align-items: center;
}

.produto-img img {
    max-width: 60%;
    min-width: 100px;
}

input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    text-overflow: ellipsis;
    outline-color: var(--verde);
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

input:disabled {
    background-color: #dcdcdc;
}

.controle {
    display: flex;
    left: 0;
    right: 0;
    justify-content: space-between;
    align-items: center;
}

.controle button {
    padding: 0rem 0.55rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
}

.input-anexos input {
    font-size: 14px;
}

.input-anexos {
    margin-bottom: 20px;
}

.adc-anexo {
    display: flex;
    align-items: center;
    gap: 5px;
    border: 1px solid #cdcdcd;
    padding: 5px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: -4px 4px 0px 0px var(--cinza);
}

.adc-anexo:hover {
    transition: all 0.2s;
    border-radius: 4px;
    background-color: #fff;
}

.adc-anexo:hover h3 {
    color: var(--verde);
}

.novo-anexo {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: space-between;
}

.novo-anexo h3 {
    color: var(--cinza-escuro);
    font-size: 14px;
}

.novo-anexo h3 small {
    font-size: 11px;
}
.btn-audio {
    position: absolute;
    right: 44px;
    top: 1px;
    bottom: 1px;
    padding: 0 10px;
    border-radius: 50px;
    background-color: transparent;
    display: flex;
    border: none;
    align-items: center;
}

.btn-audio img {
    width: 24px;
}

#btnVoiceActivated {
    background-color: #56bb8f;
    transform: scale(1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(109, 218, 136, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(109, 219, 128, 0.4);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(110, 243, 105, 0.5);
    }
}

.btn-plus {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.btn-minus {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: transparent;
}

.input-codigo,
.input-quantidade {
    display: flex;
    align-items: center;
    position: relative;
}

.input {
    margin-top: 5px;
    align-items: center;
}

.input-validade input,
.input-quantidade input {
    font-size: 15px;
    padding: 12px 8px;
    background-color: #fff;
}

.input-quantidade input {
    text-align: center;
    padding: 14px 8px;
}

.container {
    margin-top: 20px;
}

.containerQV {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
}

.tela-over {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.label-float {
    position: relative;
    padding-top: 13px;
}

.label-float input {
    border: 1px solid var(--cinza);
    border-radius: 4px;
    min-width: 180px;
    font-size: 16px;
    transition: all 0.1s ease-out;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -webkit-appearance: none;
}

.label-float input::placeholder {
    color: transparent;
}

.label-float label {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 20px;
    margin-top: 13px;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
}

.label-float input:required:invalid + label {
    color: var(--cinza);
}

.label-float input:focus + label,
.label-float input:not(:placeholder-shown) + label {
    font-size: 13px;
    margin-top: -5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: #fff;
    color: var(--vermelho);
}

.container-save {
    width: 100%;
    border-top: 1px solid var(--cinza);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 15px;
    display: flex;
    justify-content: space-between;
}

.btn-scan {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 10px;
    background-color: transparent;
    display: flex;
    border: none;
    align-items: center;
}

.mensagemErro {
    color: var(--vermelho);
    font-size: 0.7rem;
    margin-top: 5px;
}

.input-suggestion {
    width: 100%;
    cursor: pointer;
}

.label {
    display: flex;
    align-items: center;
}

.label span {
    font-size: 16px;
}

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 0;
    top: 20%;
    right: 0;
    margin: 18px;
    background-color: #fff;
    border-radius: 5px;
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 15px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 23px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 8%;
    padding: 10px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 7px 15px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-btn button:hover {
    position: relative;
    top: 5px;
    background-color: var(--verde);
    color: #fff;
}

.remove-validade {
    position: absolute;
    display: flex;
    z-index: 1;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: #878787;
    padding: 2px;
    border: none;
    cursor: pointer;
}

.remove-validade img {
    width: 20px;
}

.inputQV {
    height: 48px;
}

@media (max-width: 359px) {
    .label span {
        font-size: 14px;
    }

    input {
        font-size: 15px;
    }

    .nova-validade h3 {
        font-size: 13px;
    }

    .remove-validade img {
        width: 20px;
    }

    .tela-mensagem h3 {
        font-size: 15px;
    }

    .cancel-msg {
        font-size: 20px;
    }
}

@media (max-height: 510px) {
    .cancel-msg {
        font-size: 18px;
    }

    .cancel-btn {
        margin-top: 5%;
    }
}

@media (max-width: 327px) {
    .containerQV {
        display: flex;
        flex-direction: column;
    }
    .cancel-msg {
        font-size: 16px !important;
    }

    .tela-mensagem h3 {
        text-align: center;
    }

    .tooltip-qnt {
        margin-left: 80px;
    }
}
</style>
